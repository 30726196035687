<template>
	<div class='a-crm-data-table-container'>
		<table class='a-crm-data-table'>
			<thead>
				<tr>
					<th v-for='(item, i) in headers' :key='i'>
						{{item.text}}
					</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(item, i) in items" :key='i' class='isAction' @click='$emit("action-row", item)'>
					<td :style="'color: #fff; background-color: ' + item.crmStatus.color">{{item.number}}</td>
					<td>{{item.tourist}}</td>
					<td>{{item.countryName}}</td>
<!--					<td>{{item.balance}}</td>-->
					<td>{{item.price}}</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
	export default {
		props: ['headers', 'items', 'isAction'],
		methods: {
			tdStyle(item) {
				if(item.color && item.BgColor) {
					return `color: ${item.color}; background-color: ${item.BgColor}`
				}
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-crm-data-table {
		width: 100%;
		&-container {
			width: 100%;
			overflow-y: hidden;
			overflow-x: auto;
		}
		tr {
			transition: background-color $transition;
			border-bottom: 1px solid transparent;
			&:nth-child(even) {
				background-color: rgba($cyprus, .03);
			}
			&.isAction {
				cursor: pointer;
				&:hover {
					background-color: rgba($green-persian, .3);
					td:first-child:before {
						width: 6px;
					}
				}
			}
		}
		th,
		td {
			font-size: 14px;
			padding: 12px;
			text-align: center;
			white-space: nowrap;
		}
		th {
			background-color: rgba($green-persian, .1);
		}
		td {
			font-size: 13px;
			padding-top: 10px;
			padding-bottom: 10px;
			&:first-child {
				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					width: 0;
					background-color: $cyprus;
					transition: width $transition;
				}
			}
		}
	}
</style>
