<template>
    <div>
        <a-crm-container>
            <template>
                <div class='a-crm-content'>
                    <div class='a-crm-content-box'>
                        <div>
                            <div class='a-crm-content-group'>
                                <p class='a-sub-heading a-crm-content-title'>{{ $t("crm.filter") }}</p>

                                <div class="row">
                                    <div class="col-6">
                                        <v-text-field
                                            :label='$t("crm.order_number")'
                                            :placeholder='$t("crm.order_number_placeholder")'
                                            v-model="form.number"
                                        />
                                    </div>
                                    <div class="col-6">
                                        <v-text-field
                                            :label='$t("crm.customer")'
                                            :placeholder='$t("crm.last_name_placeholder")'
                                            v-model="form.surname"
                                        />
                                    </div>
                                </div>

                            </div>
                            <div class='a-crm-content-group'>
                                <div class="row">
                                    <div class="col-4">
                                        <a-autocomplete
                                            :label='$t("crm.country_of_destination")'
                                            :placeholder='$t("crm.enter_the_country")'
                                            v-model='form.country'
                                            :requestName='getAutocompleteCountries'
                                            small
                                            item-value='code'
                                            return-object
                                            actionInitial
                                            initialLoading
                                            append-icon='arrow-down'
                                        />
                                    </div>
                                    <div class="col-4">
                                        <a-select
                                            :label='$t("crm.order_type")'
                                            :placeholder='$t("crm.not_selected")'
                                            :options='types'
                                            v-model="form.type"
                                            item-value="code"
                                            xsmall
                                        />
                                    </div>
                                    <div class="col-4">
                                        <a-select
                                            :label='$t("crm.booking_status")'
                                            :placeholder='$t("crm.not_selected")'
                                            :options='statuses'
                                            v-model="form.status"
                                            item-value="code"
                                            xsmall
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class='a-crm-content-group'>
                                <div class="row">
                                    <div class="col-6 col-md-6">
                                        <div class="row a-crm-content-date-row">
                                            <div class="col-6 a-crm-content-date-col">
                                                <div class='a-crm-content-date'>
                                                    <p class='app-label'>{{ $t("crm.date_of_arrival_from") }}:</p>
                                                    <date-picker
                                                        valueType="format"
                                                        v-model="form.dateFrom"
                                                        :placeholder='$t("crm.date")'
                                                        :lang="$route.params.lang ? $route.params.lang : 'uk'"
                                                        class='mx-datepicker-small'
                                                        format="YYYY-MM-DD"
                                                        :disabled-date="date => date <= new Date().setHours(0, 0, 0, 0)"
                                                        :append-to-body='false'
                                                        :editable='false'
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-6 a-crm-content-date-col">
                                                <div class='a-crm-content-date'>
                                                    <p class='app-label'>{{ $t("crm.date_of_arrival_on") }}:</p>
                                                    <date-picker
                                                        valueType="format"
                                                        v-model="form.dateTo"
                                                        :placeholder='$t("crm.date")'
                                                        :lang="$route.params.lang ? $route.params.lang : 'uk'"
                                                        class='mx-datepicker-small'
                                                        format="YYYY-MM-DD"
                                                        :disabled-date="date => date <= new Date().setHours(0, 0, 0, 0)"
                                                        :append-to-body='false'
                                                        :editable='false'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <div class="row a-crm-content-date-row">
                                            <div class="col-6 a-crm-content-date-col">
                                                <div class='a-crm-content-date'>
                                                    <p class='app-label'>{{ $t("crm.date_of_booking_from") }}:</p>
                                                    <date-picker
                                                        valueType="format"
                                                        v-model="form.dateFromBooking"
                                                        :placeholder='$t("crm.date")'
                                                        :lang="$route.params.lang ? $route.params.lang : 'uk'"
                                                        class='mx-datepicker-small'
                                                        format="YYYY-MM-DD"
                                                        :disabled-date="date => date <= new Date().setHours(0, 0, 0, 0)"
                                                        :append-to-body='false'
                                                        :editable='false'
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-6 a-crm-content-date-col">
                                                <div class='a-crm-content-date'>
                                                    <p class='app-label'>{{ $t("crm.date_of_booking_on") }}:</p>
                                                    <date-picker
                                                        valueType="format"
                                                        v-model="form.dateToBooking"
                                                        :placeholder='$t("crm.date")'
                                                        :lang="$route.params.lang ? $route.params.lang : 'uk'"
                                                        class='mx-datepicker-small mx-datepicker-right'
                                                        format="YYYY-MM-DD"
                                                        :disabled-date="date => date <= new Date().setHours(0, 0, 0, 0)"
                                                        :append-to-body='false'
                                                        :editable='false'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class='a-crm-content-group-buttons'>
                            <v-button @click="filterOrder"
                                      xxsmall
                                      color="green-persian white--text">{{ $t("account.buttons.find") }}
                            </v-button>
                        </div>
                    </div>

                    <div class="a-crm-data-table-my-orders-preloader" v-if='loading'>
                        <div>
                            <div class='a-crm-data-table-my-orders-preloader-icon'>
                                <span v-html="require(`!html-loader!@/assets/baggage.svg`)"/>
                            </div>
                        </div>
                    </div>
                    <a-data-table
                        :headers='headers'
                        :items='orders'
                        @action-row='viewCard($event)'
                        :isAction='true'
                        class='a-crm-data-table-my-orders'
                        v-else
                    />
                </div>
            </template>
        </a-crm-container>
    </div>
</template>

<script>
import ACrmContainer from '@/views/crm/container';
import ADataTable from '@/views/crm/order-data-table';
import {i18n} from '@/plugins/i18n';
import {cabinet, getAutocompleteCountries, getAutocompleteCRMStatuses} from '@/services/request';
import {getAutocompleteCRMTypes} from "../../services/request";

export default {
    data: () => ({
        form: {
            number: null,
            surname: null,
            type: null,
            status: null,
            country: null,
            dateFrom: null,
            dateTo: null,
            dateFromBooking: null,
            dateToBooking: null,
        },
        statuses: [{code: null, name: i18n.t('crm.not_selected')}],
        types: [{code: null, name: i18n.t('crm.not_selected')}],
        items: [],
        orders: [],
        loading: false
    }),
    computed: {
        headers() {
            return [
                {text: i18n.t("crm.number"), value: 'number', color: '#ffffff', BgColor: '#00B796'},
                {text: i18n.t("crm.tourist"), value: 'tourist'},
                {text: i18n.t("crm.country"), value: 'countryName'},
                // {text: i18n.t("crm.balance"), value: 'balance'},
                {text: i18n.t("crm.price"), value: 'price'}
            ]
        },
    },
    components: {
        ACrmContainer,
        ADataTable
    },
    mounted() {
        console.log(1)
        this.getStatuses();
        console.log(2)
        this.getTypes();
        console.log(3)
        this.getItems();
    },
    methods: {
        getAutocompleteCountries,
        viewCard($event) {
            this.$router.push({
                name: "my-order",
                params: {id: $event.number, lang: this.$route.params.lang}
            })
        },
        getItems() {
            this.loading = true;
            cabinet.order.index().then(r => {
                this.items = r.data;
                this.filterOrder();
            }).finally(() => this.loading = false);
        },
        getStatuses() {
            getAutocompleteCRMStatuses().then(r => {
                this.statuses = this.statuses.concat(r.data);
            });
        },
        getTypes() {
            getAutocompleteCRMTypes().then(r => {
                this.types = this.types.concat(r.data);
            });
        },
        filterOrder() {
            this.orders = [];
            this.items.forEach(item => {
                if (this.form.number) {
                    if (this.form.number != item.number) return;
                }

                if (this.form.surname) {
                    if (item.surname.indexOf(this.form.surname) === -1) return;
                }

                if (this.form.type) {
                    if (this.form.type !== item.crmType.code) return;
                }

                if (this.form.status) {
                    if (this.form.status !== item.crmStatus.code) return;
                }

                if (this.form.country) {
                    if (this.form.country !== item.countryCode) return;
                }

                if (this.form.dateFrom) {
                    if (item.dateFrom <= this.form.dateFrom) return;
                }

                if (this.form.dateTo) {
                    if (item.dateTo >= this.form.dateTo) return;
                }

                if (this.form.dateFromBooking) {
                    if (item.dateBooking <= this.form.dateFromBooking) return;
                }

                if (this.form.dateToBooking) {
                    if (item.dateBooking >= this.form.dateToBooking) return;
                }


                let order = Object.assign({
                    price: this.$helpers.amount(item.amount, 'EUR'),
                    balance: this.$helpers.amount(0, 'EUR')
                }, item)

                this.orders.push(order);
            });
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/global/variables";
@import "@/styles/global/mixins";

.a-crm-data-table-my-orders {
    margin-top: 50px;

    td {
        min-width: 150px;
    }

    &-preloader {
        padding: 50px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        @include minw($grid-breakpoints-xs) {
            height: calc(100% - 50px * 2);
        }

        &-icon {
            width: 125px;
            height: 132px;
            margin: auto;
            margin-bottom: 30px;
            @include minw($grid-breakpoints-xs) {
                width: 201px;
                height: 213px;
                margin-bottom: 40px;
            }
        }

        &-title {
            font-size: 25px;
            font-weight: 700;
            margin-bottom: 16px;
            @include minw($grid-breakpoints-xs) {
                font-size: 20px;
            }
        }

        &-text {
            font-size: 14px;
            font-weight: 400;
            @include minw($grid-breakpoints-xs) {
                font-size: 16px;
            }
        }
    }
}
</style>
